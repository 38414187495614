<template>
  <div @mousedown="captureMouse">
    <NoData v-if="hideNoData && !tableData.length" :isSmall="true" :noDataMsg="noDataMsg" />
    <b-table
      v-else
      class="rowClickable"
      :class="{ hideHeader: hideHeader }"
      :loading="isLoading"
      :hoverable="true"
      :default-sort="sortField"
      :default-sort-direction="sortOrder"
      :data="tableData"
      :columns="columns"
      :selected="value"
      @update:selected="v => this.$emit('input', v)"
      @click="showContextMenu"
      @dblclick="contextDefault"
      :row-class="rowClass"
      :checkable="checkable"
      :checked-rows="checkedRows"
      @check="(l, r) => $emit('check', l, r)"
    >
      <slot />

      <template v-slot:empty>
        <section :class="{ section: !smallNoData && !hideNoData }">
          <div class="content has-text-grey has-text-centered">
            <template v-if="isLoading">
              <LoadingData />
            </template>
            <template v-else>
              <NoData v-if="!hideNoData" :isSmall="true" />
            </template>
          </div>
        </section>
      </template>
    </b-table>

    <ContextMenu
      ref="ctx"
      :edit="canEdit"
      @edit="contextDefault"
      :menuOptions="menuOptions"
      @menu-clicked="v => $emit('menu-clicked', v)"
    />
  </div>
</template>

<script>
import ContextMenu from "@/components/ContextMenu";
import NoData from "@/components/NoData.vue";
import LoadingData from "@/components/LoadingData.vue";
import ContextMenuMixin from "@/mixins/contextMenuMixin";

export default {
  emits: ["headerIconClicked", "contextDefaultClicked", "getData", "check"],
  mixins: [ContextMenuMixin],
  components: { ContextMenu, NoData, LoadingData },
  props: {
    tableData: { type: Array },
    columns: { type: Array },
    sortField: { type: String },
    sortOrder: { type: String, default: "asc" },
    isLoading: { type: Boolean, required: true },
    rowClass: { type: [Function, String] },
    value: { type: Object, required: true }, // selectedRow
    menuOptions: { type: Array, default: null },
    checkable: { type: Boolean },
    smallNoData: { type: Boolean },
    hideNoData: { type: Boolean },
    canEdit: { type: Boolean, default: true },
    noDataMsg: { type: String, default: "No Data found" },
    hideHeader: { type: Boolean }
  },
  data() {
    return {
      mousedownEvent: null,
      checkedRows: []
    };
  },
  methods: {
    headerIconClicked(iconName) {
      if (iconName === "refresh") this.getData();
      else this.$emit("headerIconClicked", iconName);
    },
    getData() {
      this.$emit("getData");
    },
    isSelectable() {
      return !this.checkable;
    }
  },
  created() {
    this.getData();
  }
};
</script>
